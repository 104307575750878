import React, { useState, useRef, useEffect } from 'react';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === process.env.REACT_APP_USERNAME && password === process.env.REACT_APP_PASSWORD) {
      onLogin();
    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <div style={{ maxWidth: '300px', margin: '100px auto', padding: '20px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', borderRadius: '8px' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
        />
        <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: '#3498db', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Login</button>
      </form>
      {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
    </div>
  );
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [threadId, setThreadId] = useState(null);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (isLoggedIn) {
      const savedMessages = localStorage.getItem('chatMessages');
      if (savedMessages) {
        setMessages(JSON.parse(savedMessages));
      }
      createThread().catch(error => handleError(error, 'creating thread'));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      localStorage.setItem('chatMessages', JSON.stringify(messages));
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }
  }, [messages, isLoggedIn]);

  const createThread = async () => {
    try {
      const threadResponse = await fetch('https://api.openai.com/v1/threads', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'OpenAI-Beta': 'assistants=v2',
          'Content-Type': 'application/json',
        },
      });
      
      if (!threadResponse.ok) {
        throw new Error(`HTTP error! status: ${threadResponse.status}`);
      }
      
      const threadData = await threadResponse.json();
      setThreadId(threadData.id);
    } catch (error) {
      throw error;
    }
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim() || !threadId) return;

    const newMessage = { role: 'user', content: inputMessage };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInputMessage('');
    setIsLoading(true);

    try {
      await addMessageToThread(threadId, inputMessage);
      const assistantMessage = await createAndRunAssistant(threadId);
      setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: assistantMessage }]);
    } catch (error) {
      handleError(error, 'sending message');
    } finally {
      setIsLoading(false);
    }
  };

  const addMessageToThread = async (threadId, content) => {
    const messageResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'OpenAI-Beta': 'assistants=v2',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ role: 'user', content: content }),
    });

    if (!messageResponse.ok) {
      throw new Error(`Failed to add message: ${messageResponse.status}`);
    }
  };

  const createAndRunAssistant = async (threadId) => {
    const runResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'OpenAI-Beta': 'assistants=v2',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ assistant_id: process.env.REACT_APP_ASSISTANT_ID }),
    });

    if (!runResponse.ok) {
      throw new Error(`Failed to create run: ${runResponse.status}`);
    }

    const runData = await runResponse.json();
    let runStatus = await pollRunStatus(threadId, runData.id);

    if (runStatus === 'completed') {
      const messagesResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'OpenAI-Beta': 'assistants=v2',
        },
      });

      if (!messagesResponse.ok) {
        throw new Error(`Failed to retrieve messages: ${messagesResponse.status}`);
      }

      const messagesData = await messagesResponse.json();
      return messagesData.data[0].content[0].text.value;
    } else {
      throw new Error('Assistant run failed or timed out');
    }
  };

  const pollRunStatus = async (threadId, runId) => {
    let status = 'in_progress';
    let attempts = 0;
    const maxAttempts = 30;

    while (status === 'in_progress' && attempts < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, 1000));

      const statusResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs/${runId}`, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'OpenAI-Beta': 'assistants=v2',
        },
      });

      if (!statusResponse.ok) {
        throw new Error(`Failed to check run status: ${statusResponse.status}`);
      }

      const statusData = await statusResponse.json();
      status = statusData.status;
      attempts++;
    }

    return status;
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('purpose', 'assistants');

        const response = await fetch('https://api.openai.com/v1/files', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        const attachResponse = await fetch(`https://api.openai.com/v1/assistants/${process.env.REACT_APP_ASSISTANT_ID}/files`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ file_id: data.id }),
        });

        if (!attachResponse.ok) {
          throw new Error(`HTTP error! status: ${attachResponse.status}`);
        }

        setUploadedFiles(prev => [...prev, { name: file.name, id: data.id }]);
        setAlert({ show: true, message: `File "${file.name}" uploaded and attached to assistant.`, type: 'success' });
      } catch (error) {
        handleError(error, 'uploading file');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFileDownload = async (fileId) => {
    try {
      const response = await fetch(`https://api.openai.com/v1/files/${fileId}/content`, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'OpenAI-Beta': 'assistants=v2',
        },
      });
      if (!response.ok) throw new Error('Failed to download file');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'download';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleError(error, 'downloading file');
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setAlert({ show: true, message: 'Copied to clipboard!', type: 'success' });
    }, (err) => {
      console.error('Could not copy text: ', err);
      setAlert({ show: true, message: 'Failed to copy. Please try again.', type: 'error' });
    });
  };

  const clearChat = () => {
    setMessages([]);
    localStorage.removeItem('chatMessages');
  };

  const handleError = (error, context) => {
    console.error(`Error in ${context}:`, error);
    let errorMessage = 'An unexpected error occurred. Please try again.';
    if (error.message.includes('401')) {
      errorMessage = 'Authentication failed. Please check your API key.';
    } else if (error.message.includes('404')) {
      errorMessage = 'The requested resource was not found. Please check your Assistant ID.';
    } else if (error.message.includes('429')) {
      errorMessage = 'Too many requests. Please try again later.';
    }
    setAlert({ show: true, message: errorMessage, type: 'error' });
  };

  const LoadingSpinner = () => (
    <div style={{
      display: 'inline-block',
      width: '20px',
      height: '20px',
      border: '2px solid #f3f3f3',
      borderTop: '2px solid #3498db',
      borderRadius: '50%',
      animation: 'spin 1s linear infinite',
    }}></div>
  );

  if (!isLoggedIn) {
    return <Login onLogin={() => setIsLoggedIn(true)} />;
  }

  return (
    <div style={{
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      borderRadius: '8px',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
      }}>
        <img 
          src="/glencadia-logo.png" 
          alt="Glencadia Logo" 
          style={{
            height: '50px',
            marginRight: '20px',
          }}
        />
        <h1 style={{color: '#2c3e50'}}>Customer Service Assistant</h1>
      </div>
      <div 
        ref={chatContainerRef}
        style={{
          height: '400px',
          overflowY: 'auto',
          border: '1px solid #ddd',
          padding: '10px',
          marginTop: '20px',
          borderRadius: '8px',
        }}
      >
        {messages.map((msg, index) => (
          <div key={index} style={{
            marginBottom: '10px',
            textAlign: msg.role === 'user' ? 'right' : 'left'
          }}>
            <div style={{
              display: 'inline-block',
              padding: '8px 12px',
              borderRadius: '18px',
              maxWidth: '70%',
              backgroundColor: msg.role === 'user' ? '#3498db' : '#ecf0f1',
              color: msg.role === 'user' ? 'white' : 'black',
            }}>
              {msg.content}
              {msg.role === 'assistant' && (
                <button onClick={() => copyToClipboard(msg.content)} style={{
                  marginLeft: '5px',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}>📋</button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div style={{marginTop: '20px', display: 'flex'}}>
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          style={{
            flexGrow: 1,
            padding: '10px',
            borderRadius: '20px',
            border: '1px solid #ddd',
            marginRight: '10px',
          }}
        />
        <button 
          onClick={handleSendMessage} 
          disabled={isLoading}
          style={{
            padding: '10px 20px',
            borderRadius: '20px',
            border: 'none',
            backgroundColor: '#2ecc71',
            color: 'white',
            cursor: 'pointer',
          }}
        >
          {isLoading ? <LoadingSpinner /> : 'Send'}
        </button>
      </div>
      <div style={{marginTop: '20px'}}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
        <button 
          onClick={() => fileInputRef.current.click()}
          style={{
            padding: '10px 20px',
            borderRadius: '20px',
            border: 'none',
            backgroundColor: '#3498db',
            color: 'white',
            cursor: 'pointer',
            marginRight: '10px',
          }}
        >
          Upload File
        </button>
        <button 
          onClick={clearChat}
          style={{
            padding: '10px 20px',
            borderRadius: '20px',
            border: 'none',
            backgroundColor: '#e74c3c',
            color: 'white',
            cursor: 'pointer',
          }}
        >
          Clear Chat
        </button>
      </div>
      {alert.show && (
        <div style={{
          marginTop: '10px', 
          padding: '10px', 
          backgroundColor: alert.type === 'error' ? '#ffebee' : '#e8f5e9',
          color: alert.type === 'error' ? '#c62828' : '#2e7d32',
          borderRadius: '5px',
        }}>
          {alert.message}
        </div>
      )}
      <div style={{marginTop: '20px'}}>
        <h3>Uploaded Files:</h3>
        <ul>
          {uploadedFiles.map((file, index) => (
            <li key={index}>
              {file.name} - <button onClick={() => handleFileDownload(file.id)}>Download</button>
            </li>
          ))}
        </ul>
      </div>
      <style jsx>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default App;